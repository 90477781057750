function setUpAdvancedSearch()
{
    $('[data-toggle="tooltip"]').tooltip();
    $(".advanced_search_button").on("click", function() {
        advancedSearch();
    });

    $(".advanced_clear_button").on("click", function() {
        // Clear the textarea when resetting
        $('#advancedQuery').val('');
        clearAdvancedSearch();
    });
}

function advancedSearch()
{
    const zgRef = document.querySelector('zing-grid');

    let originalSearchQuery = $("#advancedQuery").val().trim();

    if(originalSearchQuery === '' || originalSearchQuery === undefined || originalSearchQuery === null) {
        $('#advancedQuery').val('');
        return;
    }

    // Split each search term and put a comma. The format can be each term is on a new line or comma separated etc.
    originalSearchQuery = originalSearchQuery.split("\n").join(",").split("\r").join(",").split(",").filter(function(e){return e}).join(",");

    let searchQueryArray = originalSearchQuery.split(',');

    let searchColumn = $("select[name=advanced_search_field]").val();
    const selectedPIMState = document.querySelector('input[name="status"]:checked');
  
    const params = new URLSearchParams();
    params.append("pim_state", selectedPIMState.value);
    params.append("column_id", searchColumn);
    searchQueryArray.forEach(value => params.append('search_query[]', value.toLowerCase()));
  
    // Trigger ZG to load the endpoint with query params
    zgRef.setSrc(`/pim/product/inventory/pim-get-products?${params.toString()}`);
}

function clearAdvancedSearch()
{
    const zgRef = document.querySelector('zing-grid');

    // Trigger ZG to load the endpoint in the default state
    zgRef.setSrc(`/pim/product/inventory/pim-get-products`);
}