function prepareForm(el)
{
    SC.PIM.Product.Bind.modal(el,function(){

        let pimProductIds = getProductIds();
        let productCount = Object.keys(pimProductIds).length;

        pimProductIds = Object.values(pimProductIds);

        if (productCount  === 0) {
            alert("Please select products.");
            return;
        }

        $('#modalForm .modal-content').html("");

        $('#modalForm').modal('show');

        let form = 'display-' + $(el).data('form') + '-form';

        $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {ids: pimProductIds});

    });
}

function getProducts()
{
    const zgRef = document.querySelector('zing-grid');
    zgRef.refreshGrid();
}