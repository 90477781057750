function storeSelection(zgRef, data)
{
    // 

    let productData = data.data;
    let rowIndex  = data.rowIndex;
    let pageIndex = zgRef.getPageIndex();
    let pageSize  = zgRef.getPageSize();

    if (rowIndex > (pageIndex * pageSize)) {
        // We do not want to store indexes from previous pages again
        return;
    }

    let storedRowIndexes = JSON.parse(localStorage.getItem("selectedRows"));

    let productId = productData.id;

    if (storedRowIndexes[rowIndex]) {

        // If the pages and product ids match and the row is deselected, remove it from local storage
        if (storedRowIndexes[rowIndex].page === pageIndex && storedRowIndexes[rowIndex].productId === productId && !data.checked) {
            // 
            // 

            delete storedRowIndexes[rowIndex];
            localStorage.setItem("selectedRows", JSON.stringify(storedRowIndexes));

            return;
        }

        // If the pages DO NOT match, do nothing
        if (storedRowIndexes[rowIndex].page !== pageIndex) {
            return;
        }
    }

    storedRowIndexes[rowIndex] = {page: pageIndex, rowIndex: rowIndex, productId: productId};

    localStorage.setItem("selectedRows", JSON.stringify(storedRowIndexes));
}

function retrieveSelection(zgRef)
{
    let storedRowIndexes = JSON.parse(localStorage.getItem("selectedRows"));

    let pageIndex = zgRef.getPageIndex();
    let pageSize = zgRef.getPageSize();

    // 
    // 

    Object.entries(storedRowIndexes).forEach(([key, selectedRow]) => {

        let rowIndex = (selectedRow.rowIndex - (pageSize * (pageIndex - 1)));
        // 
        // 

        if (selectedRow.page === pageIndex) {
            zgRef.selectRow(rowIndex);
        }
    });
}

function storeSelectedProducts(zgRef, zgData)
{
    let productData = zgData.data;
    let rowIndex  = zgData.rowIndex;
    let pageIndex = zgRef.getPageIndex();
    let pageSize  = zgRef.getPageSize();

    // 
    // 
    // 
    // 

    if (rowIndex > (pageIndex * pageSize)) {
        // // We do not want to store indexes from previous pages again
        return;
    }

    let productId = productData.id;

    let productIds = JSON.parse(localStorage.getItem("selectedProductIds"));

    if (productIds[productId]) {

        // If the pages match and the row is deselected, remove it from local storage
        if (productIds[productId].page === pageIndex && !zgData.checked) {
            delete productIds[productId];
            localStorage.setItem("selectedProductIds", JSON.stringify(productIds));

            return;
        }

        // If the pages DO NOT match, do nothing
        if (productIds[productId].page !== pageIndex) {
            return;
        }
    }

    productIds[productId] = {page: pageIndex, productId: productId};

    // 

    localStorage.setItem("selectedProductIds", JSON.stringify(productIds));
}