function setUpPimGrid(json, isAdvancedSearch = false)
{
    const zgRef = document.querySelector('zing-grid');

    // Set debounce time for single column filtering
    const constants = zgRef.getConstants();
    // Set debounce time in milliseconds
    constants.search.threshold = 1200;

    const columnStates = {}; // Track visibility of each column

    // Populate the dropdown
    const dropdownContent = document.getElementById("dropdownContent");

    let productAttributes = json.data.attributes;
    let allColumns = setTableColumnsFromAttributes(productAttributes, zgRef, columnStates, dropdownContent);

    if (!isAdvancedSearch) {
        // Remove the loading text from the advanced search field and assign the attributes from the API
        $("select[name=advanced_search_field]").html("");
        setAdvancedSearchColumnsFromAttributes(productAttributes);
    }

    zgRef
        .setFrozenColumnsLeft(3) // Set the first 3 columns to frozen left (checkbox, image, sku)
        .setColumns(allColumns);
}

function setTableColumnsFromAttributes(attributes, zgRef, columnStates)
{
    let productColumns = [];

    //set image as second column (first column is the checkbox)
    productColumns.push({
        index          : "id, image",
        header         : "Main Image",
        type           : "image",
        sort           : "disabled",
        frozen         : "left",
        filter         : "disabled",
        drag           : "disabled",
        "column-width" : "fitheader",
        renderer       : "render_product_image",
        editor         : "false",
    });

    // set SKU as third column
    productColumns.push({
        index       : "sku, id",
        filterIndex : "sku",
        header      : "SKU",
        frozen      : "left",
        drag        : "disabled",
        editor      : "false",
        renderer    : "render_sku",
        width       : "fit"
    });

    const dropdownContent = document.getElementById("dropdownContent");

    pushManageAttributes(dropdownContent);

    // set the rest of the attributes as columns
    Array.from(attributes).forEach((attribute) => {

        let column = {
            index  : attribute.name,
            header : attribute.label,
            type   : "text",
            width  : "fit"
        };

        if (attribute.type == 4) { // TextArea/HTML column
            column.renderer      = "render_accordion";
            column.filter        = "disabled";
            column.sort          = "disabled";
            column["cell-class"] = "accordion-flex-layout"
        }else{
            column.renderer      = "render_data";
        }

        // Disable sorting and filtering on type 7 arrays and DataBridge filters
        if (attribute.type == 7 || (!attribute.type && attribute.name == 'filters')) {
            column.filter = "disabled";
            column.sort = "disabled";
        }
        
        productColumns.push(column);

        pushToggleColumn(attribute.name, columnStates, zgRef, dropdownContent);

    });

    return productColumns;
}

// Custom renderer for the Product Image
window.render_product_image = (currentId, currentImage, DOMCell, $cell) => {
    if (!currentImage) {
        currentImage = '/src/img/image_unavailable.jpg';
    }

    return '<a href="/pim/product/inventory/get/' + currentId + '" target="_blank"> ' +
    '   <img class="lazy loading"' +
    '       src="/TimThumb.php?src=' + currentImage + '&amp;w=50&amp;h=50&amp;zc=2"' +
    '       width="50"' +
    '       height="50"' +
    '       style=""> ' +
    '</a>';
}

// Custom renderer for the Product Image
window.render_sku = (currentSku, currentId, DOMCell, $cell) => {
    return '<a href="/pim/product/inventory/get/' + currentId + '" target="_blank"> ' + currentSku + '</a>';
}


// global event handler for expanding dropdowns
expandHandler = function() {
    this.classList.toggle('active');
    this.nextElementSibling.classList.toggle('show');
}

// generate randomId for dropdowns
randomId = () => {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

// custom render function for accordion (textarea/html)
window.render_accordion = (textArea, DOMCell, $cell) => {
    if (textArea) {
        const dropdownID = randomId();
        var id = `accordion_${dropdownID}`;

        var label = textArea.replace(/(<([^>]+)>)/ig, '');

        label = label.slice(0, 25);

        return '<div>' +
                '<section class="accordion">' +
                    '<input type="checkbox" id="'+ id+ '" >' +
                    '<label for="'+ id+ '">' + label + '...</label>' +
                    '<article>' +
                        '<p class="textArea">' + textArea + '</p>' +
                    '</article>' +
                '</section>' +
            '</div>';
    }

    return '<div>-</div>';
}

window.render_data = (data, DOMCell, $cell) => {
    // Display the columns with object values as a JSON string on the grid
        if (data && typeof data === 'object') {
            if (!Array.isArray(data)) {
                // Only stringify if it's an object and not an array
                return JSON.stringify(data);
            }
        }
        return data;
}

function pushManageAttributes(dropdownContent)
{
    dropdownContent.replaceChildren();

    // Add list item
    const listItem = document.createElement('li');
    const linkATag = document.createElement('a');
    linkATag.className = 'admin-item';
    linkATag.target = '_blank';
    linkATag.href = '/pim/attribute/collection/list';
    linkATag.innerHTML = '<i class="fa fa-sliders pr-3"></i>Manage Attributes';
    listItem.appendChild(linkATag);
    dropdownContent.appendChild(listItem);

// Add divider
    const divider = document.createElement('div');
    divider.setAttribute('role', 'separator');
    divider.className = 'divider';
    dropdownContent.appendChild(divider);
}

function pushToggleColumn(column, columnStates, zgRef, dropdownContent) {
    // Example array of columns to toggle

    columnStates[column] = true;

        const linkItem = document.createElement("li");
        const linkATag = document.createElement("a");
        const linkIconTag = document.createElement("i");

        linkATag.href = "#";
        linkATag.className = "toggle-vis visible"
        linkIconTag.className = "fa fa-eye pr-3 on"
        const linkText2 = document.createTextNode(`Toggle ${column}`);


        linkATag.appendChild(linkIconTag);
        linkATag.appendChild(linkText2);

    linkATag.onclick = (e) => {
        e.preventDefault();
        if (columnStates[column]) {
            zgRef.hideColumn(column); // Hide the column
            linkIconTag.className = "fa fa-eye-slash pr-3 off"
        } else {
            zgRef.showColumn(column); // Show the column
            linkIconTag.className = "fa fa-eye pr-3 on"
        }
        columnStates[column] = !columnStates[column]; // Update state
    };


        linkItem.appendChild(linkATag);

        dropdownContent.appendChild(linkItem);
}

function setAdvancedSearchColumnsFromAttributes(attributes)
{
    // Add SKU as first element which uses the product_code id
    var o = new Option("SKU", 'sku');
    $(o).html("SKU");
    $("select[name=advanced_search_field]").append(o);

    Array.from(attributes).forEach((attribute) => {
        // Disable sorting and filtering on type 7 arrays and DataBridge filters
        if (attribute.type == 7 || (!attribute.type && attribute.name == 'filters')) {
            return;
        }
        
        assignAttributesToAdvancedSearchSelect(attribute);
    });
}

function assignAttributesToAdvancedSearchSelect(attribute)
{
    var o = new Option(attribute.label, attribute.id);
    $(o).html(attribute.label);
    $("select[name=advanced_search_field]").append(o);
}


/**
 * The following methods add an event listener to certain actions, so we can add some custom functionality.
 */
function onPageChange(zgRef)
{
    zgRef.addEventListener('grid:pagechange', (e) => {
        setTimeout(() => retrieveSelection(zgRef), 100);
    });
}

function onSelection(zgRef)
{
    zgRef.addEventListener('row:select', (e) => {
        storeSelection(zgRef, e.detail.ZGData);
        storeSelectedProducts(zgRef, e.detail.ZGData)
    });
}

function numberWithCommas(x)
{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function captionText()
{
    const zgRef = document.querySelector('zing-grid');

    let status = getStatusSelected();
    let text = '';
    switch(status) {
        case 'p_active':
            text = 'Active Products'
            break;
        case 'p_inactive':
            text = 'Inactive Products'
            break;
        default:
            text = 'All Products'
    }

    zgRef.setCaption(text);
}

// Builds url for filtering
function createFilterURL(oFilter) {
    // Returns a URL query string
    return `&filter=${encodeURIComponent(JSON.stringify(oFilter).toLowerCase())}`;
};
