<!-- PDF & Data Templte Exports -->
function prepareExportForm(el) {
    SC.PIM.Product.Bind.modal(el, function() {

        let pimProductIds = getProductIds();

        let productCount = Object.keys(pimProductIds).length;

        if (productCount > 0 && productCount <= maximumUIExports) {

            let form = 'display-' + $(el).data('form') + '-form';
            let type = $(el).data('type');

            showModal()
            loadExportImageModal(form, type, pimProductIds);

        } else if (productCount === 0) {
            alert("Please select products.");
        } else {
            alert("This feature only supports up to " + maximumUIExports + " records at this time (You selected " + productCount + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datasets.");
        }
    });
}

<!-- PDF & Data Templte Exports -->
$(function() {

    $("#pim-download-csv").click(function () {
        pimDownloadAsset('csv')
    });
    $("#pim-download-xls").click(function () {
        pimDownloadAsset('xls')
    });
    $("#pim-download-xml").click(function () {
        pimDownloadAsset('xml')
    });

    $(document).on('click', '.pim_export_as', function () {

        let pimProductIds = getProductIds();

        let productCount = Object.keys(pimProductIds).length;

        if (productCount  === 0) {
            alert("Please select products.");
            return;
        }

        let form = 'display-' + $(this).data('form') + '-form';
        let type = $(this).data('type');
        let template = $(this).data('template');
        let name = $(this).data('name');

        if ((productCount <= maxPostFields && type == 'pdf') || (productCount <= maximumUIExports)) {
            showModal();
            loadExportDataPdfModal(form, type, pimProductIds, template, name)
        } else {
            alert("This feature only supports up to " + maximumUIExports + " records at this time (You selected " + productCount + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datasets.");
        }

    });
});

function showModal()
{
    $('#modalForm .modal-content').html("");
    $('#modalForm').modal('show');
}

function loadExportImageModal(form, type, pimProductIds)
{
    $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {
        ids: pimProductIds,
        type: type
    });
}

function loadExportDataPdfModal(form, type, pimProductIds, template, name)
{
    $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {
        ids: pimProductIds,
        template: template,
        name: name,
        type: type
    });
}

function getProductIds()
{
    const zgRef = document.querySelector('zing-grid');

    let selectedProducts = zgRef.getSelectedRows();

    let pimProductIds = {};

    Array.from(selectedProducts).forEach((selectedProduct, index) => {
        pimProductIds[index] = selectedProduct.id;
    });

    return pimProductIds;
}

function pimDownloadAsset(type)
{

    let pimProductIds = getProductIds();

    let productCount = Object.keys(pimProductIds).length;

    if (productCount  === 0) {
        alert("Please select products.");
        return;
    }

    pimProductIds = Object.values(pimProductIds);

    // 1000 is the max
    if ( productCount <= 1000 ) {
        data = {
            download_type: type,
            "product_id[]": pimProductIds
        }

        formSubmit('/pim/product/download/products-as-csv', data);

    } else {
        alert("This feature only supports up to 1000 records at this time (You selected " + productCount + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datesets.")
    }
}