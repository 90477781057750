$(document).ready(function() {
    $(function () {
        $("#pim_resync_images").click(function (event) {

            let pimProductIds = getProductIds();
            let productCount = Object.keys(pimProductIds).length;

            pimProductIds = Object.values(pimProductIds);

            if (productCount  === 0) {
                alert("Please select products.");
                return;
            } else if (productCount > 1000) {
                alert(productCount + " selected. Maximum is 1000.");
                return;
            }

            showNoty("success", "Resync request sent.");

            $.ajax({
                method: "POST",
                url: '/pim/product/inventory/resync-images',
                data: {product_id: pimProductIds}
            }).done(function (msg) {
                showNoty("success", "Sync Completed, reloading data");
            });

        });
    });
});